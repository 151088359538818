
import { useNavigate } from 'react-router-dom'
import { useEffect, useContext, useRef } from 'react';
import Cookies from 'js-cookie';
import useTranslate from '../utils/useTranslate.jsx';
import { ProductContext } from '../contexts/ProductProvider.jsx';
// import { LoadIndicator } from 'devextreme-react/load-indicator';
import { httpToHttps } from '../utils/functions';


// const stockArticles = useSelector((state)=> state.state.stockArticles) //todo: articles salva tutto l'oggetto json in risposta da api, quindi contiene id, ELENCO ed error messages. Vedere come entrare
// const articles = stockArticles?.ELENCO || [] //fallback: empty array if stockArticles o ELENCO do not exist


const ProductLookupList = ({stockArticles}) => {
    // const [isLoading, setLoading] = useState(false); //controllo spostato a monte in ProductLookup
    const translate = useTranslate();
    const navigate = useNavigate();
    const currency = Cookies.get('currency')
    const role = Cookies.get('role')

    const { stockPagination, setStockPagination, loadMoreItems, stockScrollPosition, setStockScrollPosition, stockSearch, typeSearch, filter, isLoading, search  } = useContext(ProductContext)
    const stockScrollRef = useRef(null);

//hanndle scroll
    const updateBottomContent = () => {
        console.log('Reached the bottom!');
        if(filter.season != undefined && filter.department != undefined && filter.supplier != undefined){
            // setStockPagination(prevPag => prevPag + 1)
            loadMoreItems()
        } 
    };

    const handleScroll = () => {
        const scrollTop = stockScrollRef?.current.scrollTop; //value of the top from offset
        const scrollHeight = stockScrollRef?.current.scrollHeight;
        const clientHeight = stockScrollRef?.current.clientHeight;
        
        // Save scroll position during scroll
        setStockScrollPosition(scrollTop);

        // Check if the scroll has reached the bottom
        if (scrollTop + clientHeight >= scrollHeight) {
            updateBottomContent();
        }
    };

    // Set the div's scroll position to the stored value when the component mounts
    useEffect(() => {
        if (stockScrollRef?.current) {
            stockScrollRef.current.scrollTop = stockScrollPosition;
        }
    }, [stockScrollPosition]);

    //MOVED TO PROVIDER
    // //delays the search, params is the query typed by the user
    // useEffect(() => {    
    //     let delayTimer;
    //     clearTimeout(delayTimer);
    //     delayTimer = setTimeout(() => {
    //         if(stockScrollRef?.current){
    //             stockScrollRef.current.scrollTop = 0;
    //             // search() //MOVED TO PROVIDER
    //         }
    //     }, 500);
    // }, [ stockSearch, typeSearch ]);

    // //scroll top when filter change
    // useEffect(() => {
    //     if (stockScrollRef?.current){
    //         stockScrollRef.current.scrollTop = 0;
    //         setStockPagination(1)
    //     }
    // }, [filter])
  


  return (
    <>
    {/* <div id='stock-item-list' className='scroll-view'> */}
        <ul className='stock-item-ul scroll-view-ul list-unstyled ' ref={stockScrollRef} onScroll={handleScroll}>
        {stockArticles.map((article, index) => (
            <li 
                key={index}
                className='stock-item'
                onClick={()=> {navigate(`/product-lookup/${article.ID}`)}}>

                <div  className='stock-item-child py-1 d-flex justify-content-between align-items-center'>
                    <div className='stock-item-right d-flex align-items-center flex-shrink-1'>
                    {/* image */}
                        <div className='img-container d-flex justify-content-center align-items-center'>
                            {/* <img src={item.image} alt="item.title"/> */}
                            <img src={httpToHttps(article.IMAGE_01)} alt="image" />
                        </div>

                        {/* data */}
                        <div className='text-start me-2 pe-0 pe-sm-4'>
                        
                            <div> 
                                <p className="title text-uppercase fw-bold">{article.CODE}
                                    <span className='text-secondary'> {article.AUX_CODE}</span>
                                    {article.BASE_CODE != article.CODE && 
                                        <span className='text-secondary fs-12'> {article.BASE_CODE}</span>
                                    }
                                </p>
                                <p className="description">{article.DESCRIPTION}</p>
                                <p className="supplier-name text-uppercase d-inline text-secondary-emphasis">{article.VALUE_08}
                                <span className='text-uppercase text-black-50 '> {article.SUPPLIER_NAME}</span>
                                </p>    
                                
                            </div>

                            <div className="container m-0 p-0 mt-1 flex-shrink-1">
                                {/* <div className="row gx-5 flex-shrink-1"> */}
                                <div className="row flex-shrink-1">
                                    {/* <div className="col flex-shrink-1"> */}
                                    <div className="col-6 flex-shrink-1">
                                        <div className="cell">
                                            <p className={`cell-title ${(Intl.NumberFormat('it-IT').format(article.NUM_DATA_05 - article.IMP_QTY)) > 0 ? 'fw-bolder' : 'grey' }`}>{translate('Available')}</p>
                                            <p className={`cell-content ${(Intl.NumberFormat('it-IT').format(article.NUM_DATA_05 - article.IMP_QTY)) > 0 ? 'fw-bolder' : 'grey' }`}>{Intl.NumberFormat('it-IT').format(article.NUM_DATA_05 - article.IMP_QTY)}</p>
                                        </div>
                                        <div className="cell grey">
                                            <p className="cell-title">{translate('Reserved')}</p>
                                            <p className="cell-content">{Intl.NumberFormat('it-IT').format(article.IMP_QTY)}</p>
                                        </div>
                                        <div className="cell grey">
                                            <p className="cell-title">{translate('In Order')}</p>
                                            <p className="cell-content">{Intl.NumberFormat('it-IT').format(article.NUM_DATA_01)}</p>
                                        </div>                                    
                                    </div>
                                    {/* <div className="col flex-shrink-1"> */}
                                    <div className="col-6 flex-shrink-1">
                                        <div className="cell">
                                            <p className="cell-title fw-bolder">{translate('Price')}</p>
                                            <p className="cell-content fw-bolder" >{currency} {Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(article.NUM_DATA_10)}</p>
                                        </div>
                                        {(role==0 || role==1) && (
                                        <div className="cell grey">
                                            <p className="cell-title">ST%</p>
                                            <p className="cell-content">{(article.NUM_DATA_09*100).toFixed(0)} %</p>
                                        </div>
                                        )}                                    

                                        <div className="cell grey">
                                            <p className="cell-title">{translate('Color')}</p>
                                            <p className="cell-content">{article.VARIANT_DESCRIPTION}</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    {/* arrow */}
                    <div className='arrow pe-0 pe-sm-5 flex-shrink-0'>
                        <i className='fa-solid fa-chevron-right'></i>
                    </div>

                </div>                        

            </li>

            ))}
            {/* <LoadIndicator 
                visible={isLoading}
            /> */}
            {/* if not working set back the loadpanel in the mainProductLookup */}
        </ul>   
    {/* </div> */}
    </>
    
  )
}

// Set a display name for debugging purposes
ProductLookupList.displayName = 'ProductLookupList';

export default ProductLookupList

                                {/* <div className="title text-uppercase">
                                    <strong className='ms-1'>{article.CODE}</strong>
                                    <span className='text-secondary'> {article.AUX_CODE}</span>
                                </div> */}

                {/* <Link to={`/product-lookup/${item.title}`}> */}

                {/* todo: capire con che parametro fare la chiamata al BE per i dettagli del prodotto  nella pagina dettagli, presumibilmente il codice?
                comunque salvare il codice nel STORE redux oppure prenderlo da url  */}
                {/* <a href="item.url"> */}
{/* ref to item.   details to be changed upon link with backend */}

                {/* </a> */}
                {/* </Link> */}


                                {/* 
                                <div className="cell">
                                        <div className="cell-title"><p>{translate('Available')}</p></div>
                                        <div className="cell-content"><p className='fw-bolder'>{Intl.NumberFormat('it-IT').format(article.NUM_DATA_05 - article.IMP_QTY)}</p></div>
                                    </div>
                                    <div className="cell grey">
                                        <div className="cell-title"><p>{translate('Reserved')}</p></div>
                                        <div className="cell-content"><p>{Intl.NumberFormat('it-IT').format(article.IMP_QTY)}</p></div>
                                    </div>
                                    <div className="cell grey">
                                        <div className="cell-title"><p>{translate('In Order')}</p></div>
                                        <div className="cell-content"><p>{Intl.NumberFormat('it-IT').format(article.NUM_DATA_01)}</p></div>
                                    </div>                                    
                                </div>
                                <div className="col">
                                    <div className="cell">
                                        <div className="cell-title"><p>{translate('Price')}</p></div>
                                        <div className="cell-content"><p className='fw-bolder'>{currency} {Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(article.NUM_DATA_10)}</p></div>
                                    </div>
                                    {(role==0 || role==1) && (
                                    <div className="cell grey">
                                        <div className="cell-title"><p>ST%</p></div>
                                        <div className="cell-content"><p>{(article.NUM_DATA_09*100).toFixed(0)} %</p></div>
                                    </div>
                                    )}                                    

                                    <div className="cell grey">
                                        <div className="cell-title"><p>{translate('Color')}</p></div>
                                        <div className="cell-content"><p>{article.VARIANT_DESCRIPTION}</p></div>
                                    </div>
                                     
                                </div>
                                {/* <div className='col-2 pe-2 pe-sm-5'>
                                    <i className='fa fa-chevron-right align-items-center'></i>
                                </div> */}

// ProductLookupList.propTypes = {
//     stockScrollRef: PropTypes.number,
//   }
  


// original implementation without d-flex

                        {/* <table>
                            <tbody>
                                <tr>
                                    <td>Available</td>
                                    <td><strong>{item.availability}</strong></td>
                              
                                    <td>Price</td>
                                    <td><strong>{item.price}</strong></td>
                                </tr>
                                <tr>
                                    <td>Reserved</td>
                                    <td><strong>{item.reserved}</strong></td>
                              
                                    <td>ST%</td>
                                    <td><strong>{item.ST}%</strong></td>
                                </tr>
                                <tr>
                                    <td>In order</td>
                                    <td><strong>{item.inOrder}</strong></td>
                              
                                    <td>Color</td>
                                    <td><strong>{item.color}</strong></td>
                                </tr>
                            </tbody>
                        </table> */}