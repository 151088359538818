import { useContext } from 'react';
import { ProductContext } from '../contexts/ProductProvider';
import QRscanner from '../components/QRscanner'
import useTranslate from '../utils/useTranslate';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarHeader from '../components/SidebarHeader';
// import SidebarHeader from './SidebarHeader';


const QRScannerPage = () => {
  const { setQRcode, setInventoryBarcode, setInternalBarcode, setManufacturerBarcode } = useContext(ProductContext); // removed setTypeSearch, setStockSearch,
  const translate = useTranslate()
  const navigate = useNavigate()
  // const [result, setResult] = useState(null);
  const location = useLocation();

  // const [isScanning, setIsScanning] = useState(false)

  const ACTIONS = {
    'product-lookup': {
      default: {
        onScan: (code) => {
          setQRcode(code);
          navigate('/product-lookup/:id');
        },
        // title: 'Barcode reader'
      }
    },
    // TODO move QR reader to separate section with tab navigation choice 
    'inventory': {
      default: {
        onScan: (code) => {
          setInventoryBarcode(code);
          navigate('/inventory/:id');
        },
        // title: 'Barcode reader'
      }
    },
    //end of move QR
    'barcode-association': {
      internal: {
        onScan: (code) => {
          console.log('scanned', code);
          setInternalBarcode(code);
          navigate('/barcode-association', { 
            state: { 
              scannedCode: code,
              field: 'internal'
            }
          });
        },
        // title: 'Scan Internal Barcode'
      },
      manufacturer: {
        onScan: (code) => {
          setManufacturerBarcode(code)
          navigate('/barcode-association', { 
            state: { 
              scannedCode: code,
              field: 'manufacturer'
            }
          });
        },
        // title: 'Scan Manufacturer Barcode'
      }
    }
  };

  // Get the source from the state passed during navigation
  // const { source = 'product-lookup', textField = 'default' } = location.state || {}; //with defaults
  // const action = ACTIONS[source]?.[textField] || ACTIONS['product-lookup'].default;
  const { source, textField } = location.state || {};
  const action = ACTIONS[source]?.[textField] || ACTIONS[source]?.default;

  return (
    <>
      <SidebarHeader title={translate('Barcode reader')} backArrowVisible={true} />
      <QRscanner action={action}/>
    </>
  )
}
export default QRScannerPage
//fine giapponese
