import { createContext, useCallback, useContext } from 'react';
import Subject from '../api/ApiCallsSubject';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import Helper from '../api/Helper';

import notify from 'devextreme/ui/notify'; 
import config from '../utils/constants/config';
import Item from '../api/ApiCallsProductLookup';
import axios from 'axios';
import { AuthContext } from './LoginAuthProvider';
import useTranslate from '../utils/useTranslate';
import { endOfMonth, startOfMonth } from 'date-fns';
import Docs from '../api/ApiCallsDocs';


const ProductContext = createContext();

const ProductProvider = ({ children }) => {   
  const translate = useTranslate()  
  const { getStores } = Docs()
  const role = Cookies.get('role')
  
    const [filter, setFilter] = useState(
      {
        season: Cookies.get('stock-season'),
        department: config.all,
        supplier: config.all,
      }
    )

    //used in children to populate
    const [seasons, setSeasons] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    
    //to have updated values from the store
    const [stockSearch, setStockSearch] = useState(''); //stockSearch is taken from store at component mount
    const [typeSearch, setTypeSearch] = useState('Item');
    const [stockArticles, setStockArticles] = useState([]);

    const [ availableOnly, setAvailableOnly] = useState(Cookies.get('lookup-available-only') == 'true' ? true : false)  //TODO capire se volgiamo salvarlo nei cookies
    
    const [stockPagination, setStockPagination] = useState(1);
    const [stockScrollPosition, setStockScrollPosition] = useState(0)//saves scroll position to allow resume when navigate back
    
    
    // const [season, setSeason] = useState([]); // this comes from cookes
    const [stockSeason, setStockSeason] = useState('');
    const [stockDepartment, setStockDepartment] = useState();
    const [stockSupplier, setStockSupplier] = useState();

    const [activeRequestCount, setActiveRequestCount] = useState(0); //no need to export, used only here
    const [isLoading, setLoading] = useState(false);
    const [noResultsMessage, setNoResultsMessage] = useState(false);

    const [activeRequestCountMoreItems, setActiveRequestCountMoreItems] = useState(0);
    const [isLoadingMoreItems, setLoadingMoreItems] = useState(false);


    const { getData } = Item();
    const abortControllerRef = useRef(null);
    const abortControllerMoreItemsRef = useRef(null);

//sales dashboard --> saved in provider to avoid re-fetch at each component load
    const [filterDash, setFilterDash] = useState({
      supplier: config.all,
      store: config.all,
    })
    const [suppliersDash, setSuppliersDash] = useState([]);
    const [stores, setStores] = useState([]);

    const [dashboardSupplier, setDashboardSupplier] = useState('');
    const [dashboardSupplierId, setDashboardSupplierId] = useState('');
    const [dashboardStore, setDashboardStore] = useState('');
    const [dashboardStoreId, setDashboardStoreId] = useState('');

    const now = new Date();
    const [calendarDate, setCalendarDate] = useState(sessionStorage.getItem('calendarDate') ? new Date(sessionStorage.getItem('calendarDate')) : now);  //todo: set by the click on calendar

    // const [tab, setTab] = useState(sessionStorage.getItem('timeFrame')? sessionStorage.getItem('timeFrame') : 'day');
    const [timeFrame, setTimeFrame] = useState(sessionStorage.getItem('timeFrame')? sessionStorage.getItem('timeFrame') : 'day');

    const [salesByStore, setSalesByStore] = useState();
    const [salesByChannel, setSalesByChannel] = useState();
  
    const [isGross, setIsGross] = useState();
    const [isCumulative, setIsCumulative] = useState(false);

    const [dashboardRawData, setDashboardRawData] = useState([])
  // end dash

  //QR scanner
    const [QRcode, setQRcode] = useState('')
    const [errors, setErrors] = useState(null)


  // orders
    const [filterOrders, setFilterOrders] = useState({
      store: config.all,
      channel: config.all,
      status: config.all,
      // archive: `${translate('this month')} (default)`
      history: 'this month'
    })
    const [saleChannels, setSaleChannels] = useState([])
    const [ordersStatus, setOrdersStatus] = useState('');
    const [ordersStore, setOrdersStore] = useState('');
    const [ordersStoreId, setOrdersStoreId] = useState('');
    const [ordersChannel, setOrdersChannel] = useState('');
    const [ordersChannelId, setOrdersChannelId] = useState('');
    const [ordersSearch, setOrdersSearch] = useState(''); //stockSearch is taken from store at component mount
    const [openClosedOrders, setOpenClosedOrders]  = useState('open');

    const [ordersPagination, setOrdersPagination] = useState(1);  //todo: capire se serve o se pesco in automatico tutti gli ordini //li pesca man mano, capire come agregare i dati, il primo che arriva in fondo fa partire la chimata che popola entrambe le pagine
    const [ordersCustScrollPosition, setOrdersCustScrollPosition] = useState(0) //todo: capire se serve
    const [ordersItemScrollPosition, setOrdersItemScrollPosition] = useState(0) //todo: capire se serve

    const [visualization, setVisualization] = useState('customer');
    const [isOrdersLoading, setOrdersLoading] = useState(false);
    const [noResultsMessageOrders, setNoResultsMessageOrders] = useState(false);

    const [datesRange, setDatesRange] = useState({
      startDate: startOfMonth(now),
      endDate: endOfMonth(now),
    })
    // const [datesRange, setDatesRange] = useState([])


    // inventory
    const [inventoryBarcode, setInventoryBarcode] = useState('')
    const [inventorySearch, setInventorySearch] = useState('')
    // const [inventoryList, setInventoryList] = useState(JSON.parse(localStorage.getItem('inventoryList')) || [])
    const [inventoryApi, setInventoryApi] = useState({
      // ID: null,
      // status: null,
      // list: []
    }) //add temporary save (understand how, i need inventory id + list)
    const [updatedInventoryList, setUpdatedInventoryList] = useState([])


    //barcode association
    const [ internalBarcode, setInternalBarcode ] = useState('') 
    const [ manufacturerBarcode, setManufacturerBarcode ] = useState('')
    

   

    // const {isAuthenticated} = useContext(AuthContext)

    //product lookup function


/////////////////////////////////////////////////////////////////////
  //   useEffect(()=>{
  //     if(filter && stockPagination) { //add isAuth to avoid calling funct befpre login  && isAuthenticated
  //       search();
  //     }
  //   }, [filter, stockPagination]) // removed , typeSearch, stockSearch, moved in delayed useEffect in product lookup list and in below use effect

  //   //trigger search with delay when user changes search or type --> in the product lookup list the stock/typeSearch triggers a change in pagination (with delay) 
  //     // --> if pagination = 1 and stock/typeSearch, search is not triggered
  //     // --> if pagination != 1 , is set to 1 and the change triggerse the above useEffect
    
    
  //     useEffect(() => {  
  //     // if(stockPagination == 1){  
  //       // if(isAuthenticated){  
  //     const delayTimer = setTimeout(() => {
  //       search()
  //     }, 300);
  //     return ()=> clearTimeout(delayTimer)
  //   // }
  //   // }
  // }, [ stockSearch, typeSearch ]);
//////////////////////////////////////////////////////////////

// da rimettere primo useEffect che controlla quando pagination cambia perch eraggiunto il bottom


  useEffect(() => {
    const delayTimer = setTimeout(() => {
      resetPaginationAndScroll();
      search();
    }, 500);
    return () => clearTimeout(delayTimer);
  }, [stockSearch, typeSearch]);

  useEffect(() => {
    resetPaginationAndScroll();
    search();
  }, [filter, availableOnly]);

  // useEffect(() => {
  //   if (stockPagination > 1) {  // Only search if it's not the initial pagination, when the stockPagination changes
  //     search();
  //   }
  // }, [stockPagination]);

  const resetPaginationAndScroll = () => {
    // setStockPagination(1); //moved after every param change that needs page reset, otherwise it does not work
    setStockScrollPosition(0);
  };

    
  
  //EX comment: //Search items based on selected filters, function is called after populating all the season, department, supplier lists
    //List articles
    const search = useCallback(async () => {
      setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
      setLoading(true);
      setErrors(null)
      //In Eversell API if ALL must remove filter
      // console.log('filter', filter);
      

      let season = filter.season == config.all ? '' : filter.season;
      let department = filter.department == config.all ? '' : filter.department;
      let supplier = filter.supplier == config.all ? '' : filter.supplier;
      let localAvailableOnly = availableOnly == true ? 1 : 0 //stock if > 0 --> send 1 and returns avail only, if = 0 returns all, avial and not avail

      season == undefined ? '' : season;
      department == undefined ? '' : department;
      supplier == undefined ? '' : supplier;

      if(typeSearch == 'Barcode'){
        season = ''
        department = ''
        supplier = ''
        localAvailableOnly = 0
      }

      let localStockSearch = stockSearch == undefined ? '' : stockSearch.replace(/\s+/g, '%');  // \s is white space, + is for multiple, /g is global ; this replaces all spaces with %, if multiple spaces in a row, just puts one %
      let localTypeSearch = typeSearch;
      let localStockPagination = stockPagination;
      
      try {
        // Abort the previous request if it exists, when user types more chars in "stockSearch param"
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        // let data = await getData(`{
        //   "$id": "1",
        //   "TOP": 0,
        //   "ID_STORE": "${Cookies.get('id_store')}",
        //   "ID_PRICING_ITEM": "efc0ce89-f8c4-4ae0-a71d-478854827877",
        //   "LAST_SELL": 0,
        //   "STOCK": 0,
        //   "ORDERED": 0,
        //   "SEASON": "${season}",
        //   "FREE_SEARCH": "${typeSearch === 'Item' ? localStockSearch : ''}",
        //   "ID_ITEM": "00000000-0000-0000-0000-000000000000",
        //   "CODE": "",
        //   "SEASON_LIST": "",
        //   "REPARTO": "${department}",
        //   "BRAND": "",
        //   "SUPPLIER": "${supplier}",
        //   "BARCODE": "${typeSearch === 'Barcode' ? localStockSearch : ''}",
        //   "P_LENGTH": "10",
        //   "P_NUMBER": "${stockPagination}"
        // }`, signal) //todo: check velocita chiamata con 10 20 50 items 

        //"STOCK":0
        let { success: success, data: data, error: error} = await getData(`{"$id":"1","TOP":0,"ID_STORE":"${Cookies.get('id_store')}","ID_PRICING_ITEM":"efc0ce89-f8c4-4ae0-a71d-478854827877","LAST_SELL":0,"STOCK":${localAvailableOnly},"ORDERED":0,"SEASON":"${season}","FREE_SEARCH":"${localTypeSearch==='Item'?localStockSearch:''}","ID_ITEM":"00000000-0000-0000-0000-000000000000","CODE":"","SEASON_LIST":"","REPARTO":"${department}","BRAND":"","SUPPLIER":"${supplier}","BARCODE":"${localTypeSearch==='Barcode'?localStockSearch:''}","P_LENGTH":"10","P_NUMBER":"${localStockPagination}"}`,signal) //todo: check velocita chiamata con 10 20 50 items 
        if(success){
        if(stockPagination != 1){ //attualm the search call does not handle pages after the 1st, "loadMoreItems" does
          data = stockArticles.concat(data) 
        }
        setStockArticles(data);
        setNoResultsMessage(data.length == 0) //bool
      } else {
        setErrors(error?.message);
      }
      } catch (error) {
        // console.log('Request canceled GAGA PROVIDER ERR', error.name);
        if (!error.name === 'CanceledError') {  //if api call not aborted 
          notify(translate(error.message) || translate('Error'), 'error', 3000);
        }
      } finally {
        setActiveRequestCount(prevCount => prevCount - 1);
      }
    }, [filter, typeSearch, stockSearch, stockArticles, availableOnly]) //removed stockPagination to fix bug Safari

    //to check the current number of ongoing calls (avoids wrong isLoading setting upon abort)
    useEffect(() => {
      setLoading(activeRequestCount > 0)
    }, [activeRequestCount])


    const loadMoreItems = async () => {
      setActiveRequestCountMoreItems(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly

      setLoadingMoreItems(true);
      setErrors(null)
      let localStockPagination = stockPagination + 1;


      let season = filter.season == config.all ? '' : filter.season;
      let department = filter.department == config.all ? '' : filter.department;
      let supplier = filter.supplier == config.all ? '' : filter.supplier;
      let localAvailableOnly = availableOnly == true ? 1 : 0 //stock if > 0 --> send 1 and returns avail only, if = 0 returns all, avial and not avail

      season == undefined ? '' : season;
      department == undefined ? '' : department;
      supplier == undefined ? '' : supplier;

      if(typeSearch == 'Barcode'){
        season = ''
        department = ''
        supplier = ''
        localAvailableOnly = 0
      }

      let localStockSearch = stockSearch == undefined ? '' : stockSearch.replace(/\s+/g, '%');  // \s is white space, + is for multiple, /g is global ; this replaces all spaces with %, if multiple spaces in a row, just puts one %
      let localTypeSearch = typeSearch;
      
      try {
        if (abortControllerMoreItemsRef.current) {
          abortControllerMoreItemsRef.current.abort();
        }
        abortControllerMoreItemsRef.current = new AbortController();
        const signal = abortControllerMoreItemsRef.current.signal;

        let { success: success, data: data, error: error} = await getData(`{"$id":"1","TOP":0,"ID_STORE":"${Cookies.get('id_store')}","ID_PRICING_ITEM":"efc0ce89-f8c4-4ae0-a71d-478854827877","LAST_SELL":0,"STOCK":${localAvailableOnly},"ORDERED":0,"SEASON":"${season}","FREE_SEARCH":"${localTypeSearch==='Item'?localStockSearch:''}","ID_ITEM":"00000000-0000-0000-0000-000000000000","CODE":"","SEASON_LIST":"","REPARTO":"${department}","BRAND":"","SUPPLIER":"${supplier}","BARCODE":"${localTypeSearch==='Barcode'?localStockSearch:''}","P_LENGTH":"10","P_NUMBER":"${localStockPagination}"}`, signal) //todo: check velocita chiamata con 10 20 50 items 
        if(success){
        // if(stockPagination != 1){
          data = stockArticles.concat(data)
        // }
        setStockArticles(data);
        setNoResultsMessage(data.length == 0) //bool
        setStockPagination(prevPag => prevPag + 1)
      } else {
        setErrors(error?.message);
      }
      } catch (error) {
        if (!error.name === 'CanceledError') {  //if api call not aborted 
          notify(translate(error.message) || translate('Error'), 'error', 3000);
        }
      } finally {
        setActiveRequestCountMoreItems(prevCount => prevCount - 1);
      }
    }


    //to check the current number of ongoing calls (avoids wrong isLoading setting upon abort)
    useEffect(() => {
      setLoadingMoreItems(activeRequestCountMoreItems > 0)
    }, [activeRequestCountMoreItems])
    

    //Get my store name using its ID
  const fetchStoreName = async () => {
    try {
      let { success: success, data: data, error: error } = await getStores(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
      // if(success){        
        let storeName = data.find((store) => store.ID == Cookies.get('id_store')).SEARCH_NAME
        Cookies.set('store_name', storeName)
      // }
    } catch (error) {
      console.log(error);
      // notify(translate(error.message) || translate('Error'), 'error', 3000);
    }
  }

    useEffect(() => {
      if(role != 0 && role != 1) {
        fetchStoreName()
      }
    }, [role]);

      
//when export sth here, also clean the data on logout in the sidebar component
  return (
    <ProductContext.Provider value={{ filter, setFilter, seasons, setSeasons, departments, setDepartments, suppliers, setSuppliers, stockSearch, setStockSearch, typeSearch, setTypeSearch, stockArticles, setStockArticles, availableOnly, setAvailableOnly, stockPagination, setStockPagination, loadMoreItems, stockScrollPosition, setStockScrollPosition, stockSeason, setStockSeason, stockDepartment, setStockDepartment, stockSupplier, setStockSupplier, isLoading, isLoadingMoreItems, setLoading, search, noResultsMessage, 

    filterDash, setFilterDash, suppliersDash, setSuppliersDash, stores, setStores, dashboardSupplier, setDashboardSupplier, dashboardSupplierId, setDashboardSupplierId, dashboardStore, setDashboardStore, dashboardStoreId, setDashboardStoreId, 
    calendarDate, setCalendarDate, 
    // tab, setTab, 
    timeFrame, setTimeFrame, salesByStore, setSalesByStore, salesByChannel, setSalesByChannel, isGross, setIsGross, isCumulative, setIsCumulative, dashboardRawData, setDashboardRawData,
    QRcode, setQRcode, errors, setErrors,
    filterOrders, setFilterOrders, ordersStatus, setOrdersStatus, ordersStore, setOrdersStore, ordersStoreId, setOrdersStoreId, ordersChannel, setOrdersChannel, ordersChannelId, setOrdersChannelId, saleChannels, setSaleChannels, ordersSearch, setOrdersSearch, openClosedOrders, setOpenClosedOrders, ordersPagination, setOrdersPagination,  ordersCustScrollPosition, setOrdersCustScrollPosition, ordersItemScrollPosition, setOrdersItemScrollPosition, visualization, setVisualization, //orders
    isOrdersLoading, setOrdersLoading, noResultsMessageOrders, setNoResultsMessageOrders, datesRange, setDatesRange,
    inventoryBarcode, setInventoryBarcode, inventorySearch, setInventorySearch, inventoryApi, setInventoryApi, updatedInventoryList, setUpdatedInventoryList, //inventory
    internalBarcode, setInternalBarcode, manufacturerBarcode, setManufacturerBarcode // barcode association
    }}>
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider };