import { format } from 'date-fns';


export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const httpToHttps = (url) => {
  return url.replace('http://', 'https://');
}

export const getStatusClass = (status) => {
  switch (status) {
    case 'INSERITO': //inserito
      return 'status-inserted';
    case 'LAVORAZIONE': //lavorazione
        return 'status-processing';
    case 'LETTERA VETTURA': //lettera vettura
      return 'status-waybill';
    case 'PARZIALMENTE EVASO': //or processed  //evaso
      return 'status-part-dispatched';
    case 'EVASO': //or processed  //evaso
      return 'status-dispatched';
    case 'RESO': //or processed  //evaso
      return 'status-returned';          
    // case 'partially returned': //or processed  //evaso
    case 'RESO PARZIALE': //or processed  //evaso
      return 'status-part-returned';
    default:
      return '';
  }
};

export const getStatusColor = (status) => {
  const colorMap = {
    'all': 'red',
    'INSERITO': "rgba(114, 165, 207, 0.5)",
    'LAVORAZIONE': "rgba(238, 186, 105, 0.5)",
    'LETTERA VETTURA': "rgba(207, 201, 99, 0.5)",
    'PARZIALMENTE EVASO': "rgba(200, 200, 200, 0.5)",
    'EVASO': "rgba(213, 213, 213, 0.5)",
    'RESO': "rgba(104, 33, 122, 0.5)",
    'RESO PARZIALE': "rgba(171, 132, 174, 0.5)"
  };
  return colorMap[status];
};

export const formatDateToYMD = (date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formatDateToYMDHM = (date) => {
  const now = new Date();
  date.setHours(now.getHours(), now.getMinutes());
  return format(date, "yyyy-MM-dd HH:mm");
};

export const isArraysEqual = (a, b) => 
    a.length === b.length && a.every((val, index) => val === b[index]);